<template>
  <AlertList>
    <div class="alert-empty-message">
      <sdAlerts
        :outlined="true"
        :closable="false"
        :showIcon="true"
        message="Warning"
        description="You must capture the payment on this order"
        type="warning"
      />
      <sdAlerts
        :outlined="true"
        :closable="false"
        :showIcon="true"
        message="Warning"
        description="For this order you must invoice the full amount. This will capture the full value from the customer. If any items can not be fulfilled a refund for that item would need to be issued."
        type="info"
      />
    </div>
  </AlertList>
  <!-- <OrderInformation /> -->
  <!-- <AddressInformation /> -->
  <!-- <PaymentMethod ref="paymentMethod" /> -->
  <ItemsToShip ref="itemsToShip" />
  <InformationPageHeader>
    <sdButton v-if="isCreate && noCapture" class="btn-submit" type="warning" @click="createInvoiceOnly">
      {{ 'Create Invoice' }}
    </sdButton>
    <sdButton v-if="isCreate && manualCapture" class="btn-submit" type="warning" @click="createInvoice">
      {{ isCreate ? 'Invoice & Capture Payment' : 'Invoices List' }}
    </sdButton>
  </InformationPageHeader>
</template>

<script>
// import OrderInformation from '../information/OrderInformation.vue';
// import AddressInformation from '../information/AddressInformation.vue';
// import PaymentMethod from './PaymentMethod.vue';
import ItemsToShip from './ItemsToShip.vue';
import { InformationPageHeader } from '../information/style';
import { useStore } from 'vuex';
import { AlertList } from '@/view/styled';
// import { useStore } from 'vuex';
// import { computed } from '@vue/runtime-core';
const mapPayment = {
  cybersourcepayment: 'cybersource',
  paypal: 'paypal',
  stripe: 'stripe',
  checkoutCom: 'checkout_com',
  barclayspayment: 'barclaysenterprise',
};

export default {
  name: 'CreateOrderInvoice',
  components: {
    // OrderInformation,
    // AddressInformation,
    // PaymentMethod,
    ItemsToShip,
    InformationPageHeader,
    AlertList,
  },
  computed: {
    isCreate() {
      return this.$route.params.invoiceId === 'create';
    },
    storeview() {
      const { state } = useStore();
      return state.auth.activeStoreView;
    },
    paymentProvider() {
      const paymentMethod = this.$store.state.orders.order?.payment?.method;
      return this.storeview[mapPayment[paymentMethod]];
    },
    noCapture() {
      return !!this.paymentProvider?.no_capture;
    },
    manualCapture() {
      return !!this.paymentProvider?.manual_capture;
    },
  },
  methods: {
    async createInvoice() {
      if (this.isCreate) {
        // const shouldCreateShipment = this.$refs['paymentMethod'].createShipment;
        const items = Object.entries(this.$refs['itemsToShip'].orderQtys)
          .filter(([key, value]) => {
            return key && value;
          })
          .map(([key, value]) => ({
            order_item_id: key,
            qty: value,
          }));

        const payload = {
          entity: {
            items,
            notify: true,
            appendComment: false,
            capture: true,
          },
        };
        console.log(payload, 'payload');
        const totalPrice = this.$refs['itemsToShip'].totalPrice;
        await this.$store.dispatch('createInvoice', { payload, orderId: this.$route.params.id, totalPrice });
      } else {
        this.$router.push({ name: 'omni-order-invoices' });
      }
    },
    async createInvoiceOnly() {
      if (this.isCreate) {
        // const shouldCreateShipment = this.$refs['paymentMethod'].createShipment;
        const items = Object.entries(this.$refs['itemsToShip'].orderQtys)
          .filter(([key, value]) => {
            return key && value;
          })
          .map(([key, value]) => ({
            order_item_id: key,
            qty: value,
          }));

        const payload = {
          entity: {
            items,
            notify: true,
            appendComment: false,
            capture: true,
          },
        };

        const totalPrice = this.$refs['itemsToShip'].totalPrice;
        await this.$store.dispatch('createInvoiceOnly', { payload, orderId: this.$route.params.id, totalPrice });
      } else {
        this.$router.push({ name: 'omni-order-invoices' });
      }
    },
  },

  setup() {
    // const { state } = useStore();
    // const possibleRefunding = computed(() => !!state.orders.invoice);

    return {
      // possibleRefunding,
    };
  },
  mounted() {
    if (!this.isCreate) {
      this.$store.commit('setInvoice', this.$route.params.invoiceId);
    }
    console.log(this.noCapture, 'noCapure');
    console.log(this.manualCapture, 'manual Capture');
  },
};
</script>

<style lang="scss" scoped>
.btn-submit {
  margin: 30px 0;
}
</style>
