<template>
  <OrderInformation />
  <PaymentMethod />
  <ItemsRefunded />
</template>
<script>
import OrderInformation from '../information/OrderInformation.vue';
import PaymentMethod from '../information/PaymentMethod.vue';
import ItemsRefunded from './components/ItemsRefunded.vue';
import { onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

export default {
  name: 'OrderRefundDetails',
  components: {
    OrderInformation,
    PaymentMethod,
    ItemsRefunded,
  },
  setup() {
    const { commit } = useStore();
    const { params } = useRoute();

    onMounted(() => {
      commit('setRefund', params.refundId);
    });
  },
};
</script>
