import Styled from 'vue3-styled-components';

const FileCardWrap = Styled.div`
  min-height: 625px;
  @media only screen and (max-width: 1199px){
    min-height: 100%;
  }
`;

const ItemsRefundedWrapper = Styled.div`
  .header {
    .ant-col {
      font-weight: bold;
    }
  }

  .ant-card-body {
    padding: 30px !important;

    .ant-row {
      margin: 10px 0;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .ant-input {
    padding: 6px 11px;
    width: 200px;
  }

  .order-total {
    .comment-label {
      margin-bottom: 15px;
    }
    textarea {
      width: 100% !important;
    }
    .refund-total {
      .ant-col {
        height: 36px !important;
      }

      .ant-input {
        width: 90px !important;
      }
    }
  }

  .btn-refund {
    margin-top: 20px;
  }
`;

export { FileCardWrap, ItemsRefundedWrapper };
