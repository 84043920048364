<template>
  <FullHeightSdCardWrapper>
    <ItemsToShipWrapper>
      <sdPageHeader title="Items Refunded" />
      <a-row justify="center" :gutter="25">
        <a-col :xs="24">
          <sdCards>
            <TableWrapper class="table-responsive">
              <a-table :dataSource="tableData" :columns="tableColumns" :pagination="{ defaultPageSize: 12 }" />
            </TableWrapper>
          </sdCards>
        </a-col>
      </a-row>

      <sdPageHeader title="Memo Total" />
      <a-row justify="center" :gutter="25" class="order-total">
        <a-col :md="12" :xs="24"></a-col>
        <a-col :md="12" :xs="24">
          <sdCards title="Credit Memo Totals" class="refund-total">
            <a-row justify="space-between" class="mb-20">
              <a-col>Subtotal</a-col>
              <a-col>{{ currencyFromCode(refund.base_currency_code) + refund.subtotal }}</a-col>
            </a-row>
            <a-row justify="space-between" class="mb-20">
              <a-col>Adjustment Refund</a-col>
              <a-col>{{ currencyFromCode(refund.base_currency_code) + refund.adjustment_negative }}</a-col>
            </a-row>
            <a-row justify="space-between" class="mb-20">
              <a-col>Adjustment Fee</a-col>
              <a-col>{{ currencyFromCode(refund.base_currency_code) + refund.adjustment_positive }}</a-col>
            </a-row>
            <a-row justify="space-between" class="mb-20">
              <a-col>Tax</a-col>
              <a-col>{{ currencyFromCode(refund.base_currency_code) + refund.tax_amount }}</a-col>
            </a-row>
            <a-divider type="horizontal" />
            <a-row justify="space-between">
              <a-col><b>Grand Total</b></a-col>
              <a-col>{{ currencyFromCode(refund.base_currency_code) + refund.grand_total }}</a-col>
            </a-row>
          </sdCards>
        </a-col>
      </a-row>
    </ItemsToShipWrapper>
  </FullHeightSdCardWrapper>
</template>

<script>
import { useStore } from 'vuex';
import { computed } from 'vue';
import { ItemsToShipWrapper } from '../../invoices/style';
import { FullHeightSdCardWrapper, TableWrapper } from '@/view/styled';
import { currencyFromCode } from '@/utility/filter';
// import { useRoute } from 'vue-router';

const tableColumns = [
  {
    title: 'Product',
    dataIndex: 'product',
    key: 'product',
  },
  {
    title: 'Price',
    dataIndex: 'price',
    key: 'price',
  },
  {
    title: 'Qty',
    dataIndex: 'qty',
    key: 'qty',
  },
  {
    title: 'Subtotal',
    dataIndex: 'sub_total',
    key: 'sub_total',
  },
  {
    title: 'Tax Amount',
    dataIndex: 'tax_amount',
    key: 'tax_amount',
  },
  {
    title: 'Discount Amount',
    dataIndex: 'discount_amount',
    key: 'discount_amount',
  },
  {
    title: 'Row total',
    dataIndex: 'row_total',
    key: 'row_total',
    align: 'left',
  },
];

export default {
  name: 'ItemsRefunded',
  components: {
    ItemsToShipWrapper,
    FullHeightSdCardWrapper,
    TableWrapper,
  },
  setup() {
    const { state } = useStore();

    const refund = computed(() => state.orders.refund ?? {});

    const tableData = computed(() =>
      state.orders.refund?.items?.length
        ? state.orders.refund.items.map((item) => {
            const { entity_id, name, sku, qty, price, row_total, tax_amount } = item;

            return {
              key: entity_id,
              product: (
                <div>
                  <div>
                    {name} - {sku}
                  </div>
                  <div>SKU: {sku}</div>
                </div>
              ),
              price: currencyFromCode(state.orders.refund.base_currency_code) + price,
              qty,
              sub_total: currencyFromCode(state.orders.refund.base_currency_code) + qty * price,
              tax_amount: currencyFromCode(state.orders.refund.base_currency_code) + tax_amount,
              discount_amount:
                currencyFromCode(state.orders.refund.base_currency_code) + state.orders.refund.discount_amount,
              row_total: currencyFromCode(state.orders.refund.base_currency_code) + row_total,
            };
          })
        : [],
    );
    return {
      tableColumns,
      tableData,
      refund,
      currencyFromCode,
    };
  },
};
</script>
