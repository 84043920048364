<template>
  <FullHeightSdCardWrapper>
    <ItemsToShipWrapper>
      <a-row justify="center" :gutter="25">
        <a-col :xs="24">
          <TableWrapper class="table-responsive">
            <a-table :dataSource="tableData" :pagination="false" :columns="tableColumns" />
          </TableWrapper>
        </a-col>
      </a-row>
      <a-row justify="end" :gutter="25" class="mt-20">
        <a-col :md="12" :xs="24">
          <Suspense>
            <template #default>
              <sdCards :title="`Shipping Summary`">
                <a-row justify="space-between">
                  <a-col>
                    <p>Shipping (ex Tax)</p>
                  </a-col>
                  <a-col
                    ><b>{{ order.shipping_amount }}</b></a-col
                  >
                </a-row>
                <a-row justify="space-between">
                  <a-col>
                    <p>Shipping Tax</p>
                  </a-col>
                  <a-col
                    ><b>{{ order.shipping_tax_amount }}</b></a-col
                  >
                </a-row>
                <a-row justify="space-between">
                  <a-col>
                    <p>Shipping Discount</p>
                  </a-col>
                  <a-col
                    ><b>- {{ order.shipping_discount_amount }}</b></a-col
                  >
                </a-row>
                <a-row justify="space-between">
                  <a-col>
                    <p>Shipping Total</p>
                  </a-col>
                  <a-col
                    ><b>{{
                      parseFloat(order.base_shipping_incl_tax - order.shipping_discount_amount).toFixed(2)
                    }}</b></a-col
                  >
                </a-row>
              </sdCards>
            </template>
            <template #fallback>
              <sdCards headless>
                <a-skeleton active />
              </sdCards>
            </template>
          </Suspense>
        </a-col>
        <a-col :md="12" :xs="24">
          <Suspense>
            <template #default>
              <sdCards :title="`Invoices Total`">
                <a-row justify="space-between">
                  <a-col>
                    <p>SubTotal</p>
                  </a-col>
                  <a-col>
                    <p>
                      <b>{{ subTotal }}</b>
                    </p>
                  </a-col>
                </a-row>
                <a-row justify="space-between">
                  <a-col>
                    <p>Shipping (ex Tax)</p>
                  </a-col>
                  <a-col
                    ><b>{{ order.shipping_amount }}</b></a-col
                  >
                </a-row>
                <a-row justify="space-between">
                  <a-col>
                    <p>Shipping Tax</p>
                  </a-col>
                  <a-col
                    ><b>{{ order.shipping_tax_amount }}</b></a-col
                  >
                </a-row>
                <a-row justify="space-between">
                  <a-col>
                    <p>Product Tax</p>
                  </a-col>
                  <a-col>
                    <p>
                      <b class="pre-formatted">{{ taxs }}</b>
                    </p>
                  </a-col>
                </a-row>
                <a-row justify="space-between">
                  <a-col>
                    <p>Grand Total</p>
                  </a-col>
                  <a-col>
                    <p>
                      <b>{{ totalInvoices }}</b>
                    </p>
                  </a-col>
                </a-row>
              </sdCards>
            </template>
            <template #fallback>
              <sdCards headless>
                <a-skeleton active />
              </sdCards>
            </template>
          </Suspense>
        </a-col>
      </a-row>
    </ItemsToShipWrapper>
  </FullHeightSdCardWrapper>
</template>

<script>
import { useStore } from 'vuex';
import { computed, onMounted, watch, reactive } from 'vue';
import { ItemsToShipWrapper } from './style';
import { FullHeightSdCardWrapper, TableWrapper } from '@/view/styled';
import { useRoute } from 'vue-router';

const tableColumns = [
  {
    title: 'Overview',
    dataIndex: 'product',
    key: 'product',
  },
  {
    title: 'Qty',
    dataIndex: 'qty_to_invoice',
    key: 'qty_to_invoice',
  },
  {
    title: 'Row total',
    dataIndex: 'row_total_formated',
    key: 'row_total_formated',
    align: 'left',
  },
];

export default {
  name: 'ItemsToShip',
  components: {
    ItemsToShipWrapper,
    FullHeightSdCardWrapper,
    TableWrapper,
  },
  setup() {
    const { state } = useStore();
    const order = computed(() => state.orders.order);
    const { params } = useRoute();
    const orderQtys = reactive({});

    const items = computed(() => {
      if (params.invoiceId === 'create') {
        return state.orders.order.items
          ? state.orders.order.items.filter((item) => item.qty_ordered > item.qty_invoiced)
          : [];
      } else {
        const currentInvoiceItemsIds = state.orders.invoice
          ? state.orders.invoice.items.map((item) => item.order_item_id)
          : [];

        return state.orders.order.items
          ? state.orders.order.items.filter((item) => currentInvoiceItemsIds.includes(item.item_id))
          : [];
      }
    });

    const reset = () => {
      items.value.map((item) => {
        orderQtys[item.item_id] = item.qty_ordered;
      });
    };

    const title = params.shipmentId === 'create' ? 'Items to Invoice' : 'Items Invoiced';

    const totalPrice = computed(() => {
      return (
        items.value.reduce((result, item) => {
          return (result += item.price_incl_tax * orderQtys[item.item_id]);
        }, 0) + order.value.shipping_incl_tax
      );
    });
    console.log(totalPrice, 'totalPrice');
    onMounted(() => {
      reset();
    });

    watch(order, () => {
      reset();
    });

    console.log(items, 'tax');

    const tableData = computed(() =>
      items.value.length
        ? items.value.map((item) => {
            const {
              item_id,
              name,
              sku,
              qty_ordered,
              qty_invoiced,
              price,
              tax_amount,
              base_discount_amount,
              row_total,
              discount_amount,
            } = item;

            let tax_per_qty =
              qty_invoiced < qty_ordered
                ? Math.floor((tax_amount / qty_ordered) * 100) / 100
                : tax_amount / qty_ordered;

            return {
              key: item_id,
              product: (
                <div>
                  <div>Part No: {sku}</div>
                  <a-tooltip placement="bottom" title={name}>
                    <router-link to="#">
                      <sdFeatherIcons type="alert-octagon" size="20" />
                    </router-link>
                  </a-tooltip>
                  <div>Ordered {qty_ordered}</div>
                  <div>Invoiced {qty_invoiced}</div>
                  <div>Unit Price: {parseFloat(price).toFixed(2)}</div>
                  <div>Unit Discount: {base_discount_amount / qty_ordered} </div>
                </div>
              ),
              qty_to_invoice:
                params.invoiceId === 'create' ? (
                  <div>
                    <>
                      <a-input
                        value={orderQtys[item_id]}
                        max={qty_ordered}
                        type="number"
                        min="0"
                        disabled
                        onChange={(e) => (orderQtys[item_id] = e.target.value ? +e.target.value : qty_ordered)}
                      />
                    </>
                  </div>
                ) : (
                  <div>{qty_invoiced}</div>
                ),
              sub_total:
                params.invoiceId === 'create'
                  ? !orderQtys[item_id]
                    ? 0
                    : orderQtys[item_id] * price
                  : qty_invoiced * price,
              tax_amount:
                params.invoiceId === 'create'
                  ? !orderQtys[item_id]
                    ? 0
                    : orderQtys[item_id] * tax_per_qty
                  : qty_invoiced * tax_per_qty,
              discount_amount:
                params.invoiceId === 'create'
                  ? !orderQtys[item_id]
                    ? 0
                    : orderQtys[item_id] * discount_amount
                  : qty_invoiced * discount_amount,
              row_total_formated:
                params.invoiceId === 'create'
                  ? !orderQtys[item_id]
                    ? 0
                    : parseFloat(orderQtys[item_id] * price + tax_amount - discount_amount).toFixed(2)
                  : row_total,
              row_total:
                params.invoiceId === 'create'
                  ? !orderQtys[item_id]
                    ? 0
                    : orderQtys[item_id] * price + tax_amount - discount_amount
                  : row_total,
            };
          })
        : [],
    );

    const subTotal = computed(() => {
      return tableData.value.reduce((total, row) => total + row.sub_total, 0.0);
    });

    const taxs = computed(() => {
      return tableData.value.reduce((total, row) => total + row.tax_amount, 0.0);
    });

    const totalInvoices = computed(() => {
      const rowTotal = tableData.value.reduce((total, row) => total + row.row_total, 0.0);
      return rowTotal
        ? parseFloat(order.value.base_shipping_incl_tax - order.value.shipping_discount_amount + rowTotal).toFixed(2)
        : 0;
    });
    return {
      order,
      reset,
      orderQtys,
      title,
      tableColumns,
      tableData,
      totalPrice,
      totalInvoices,
      subTotal,
      taxs,
    };
  },
};
</script>
